export const overlayClassName = "introjs-overlay";
export const disableInteractionClassName = "introjs-disableInteraction";
export const bulletsClassName = "introjs-bullets";
export const progressClassName = "introjs-progress";
export const progressBarClassName = "introjs-progressbar";
export const helperLayerClassName = "introjs-helperLayer";
export const tooltipReferenceLayerClassName = "introjs-tooltipReferenceLayer";
export const helperNumberLayerClassName = "introjs-helperNumberLayer";
export const tooltipClassName = "introjs-tooltip";
export const tooltipHeaderClassName = "introjs-tooltip-header";
export const tooltipTextClassName = "introjs-tooltiptext";
export const tooltipTitleClassName = "introjs-tooltip-title";
export const tooltipButtonsClassName = "introjs-tooltipbuttons";
export const arrowClassName = "introjs-arrow";
export const skipButtonClassName = "introjs-skipbutton";
export const previousButtonClassName = "introjs-prevbutton";
export const nextButtonClassName = "introjs-nextbutton";
export const doneButtonClassName = "introjs-donebutton";
export const dontShowAgainClassName = "introjs-dontShowAgain";
export const hiddenButtonClassName = "introjs-hidden";
export const disabledButtonClassName = "introjs-disabled";
export const fullButtonClassName = "introjs-fullbutton";
export const activeClassName = "active";
export const fixedTooltipClassName = "introjs-fixedTooltip";
export const floatingElementClassName = "introjsFloatingElement";
export const showElementClassName = "introjs-showElement";
